<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="preview-header">
                <div class="preview-title">{{ title }}</div>
                <div class="preview-action">
                    <div><download-button dom="#previewContent" :name="this.entity.estimateCode + '.pdf'"/></div>
                    <div class="preview-print">
                        <a @click="print">Print</a>
                    </div>
                    <div class="preview-close" @click="showDialog = false">
                        Close
                    </div>
                </div>
            </div>
            <md-content id="previewContent">
                <div class="preview-content" style="width: 780px;">
                    <div style="display: flex; border: 1px solid #ccc; padding: 10px;">
                        <div style="width: 50%; display: flex; align-items: center;">
                            <img src="../../../assets/images/logo.png" style="width: 130px; height: 50px;"/>
                        </div>
                        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                            <div style="font-weight: bold; font-size: 22px;">DỰ TOÁN CHI PHÍ ĐIỀU ĐỘNG PHƯƠNG TIỆN</div>
                            <div style="font-size: 14px;"><span>Số: </span><span style="font-weight: bold;">{{entity.estimateCode}}</span></div>
                            <div><span>Ngày: </span><span>{{entity.estimateDate}}</span></div>
                        </div>
                    </div>
                    <div style="display: flex; justify-content: center; align-items: center; padding: 10px 0;"> 
                        <span style="font-weight: bold; font-size: 18px;">Kính gửi: </span>
                        <span style="font-weight: bold; font-size: 18px; padding-left: 10px;">BGĐ Công ty PPL</span>
                    </div>
                    <div style="display: flex; flex-direction: column; padding: 10px 0;"> 
                        <p style="padding-bottom: 10px; font-size: 14px;">Tôi tên là: <span style="font-weight: bold; font-size: 15px;">{{entity.staff.fullName}}</span></p>
                        <p style="padding-bottom: 10px; font-size: 14px;">Phòng/Đơn vị công tác: <span style="font-weight: bold; font-size: 15px;">{{entity.dept.name}}</span></p>
                        <p>Đề nghị giải quyết dự toán chi phí điều động phương tiện - Mã điều động: <span style="font-weight: bold;">{{ entity.job.jobCode }}</span></p>
                    </div>
                    
                    <div>
                        <table style=" border-collapse: collapse;border-spacing: 0; min-height: auto !important;">
                            <thead>
                                <tr style="background-color: antiquewhite; font-weight: bold; font-size: 12px;">
                                    <th style="width:40px; padding: 5px 10px; border: 1px solid #e0e0e0;">STT</th>
                                    <th style="width:100px; padding: 5px 10px; border: 1px solid #e0e0e0;">Kiện hàng</th>
                                    <th style="width:100px; padding: 5px 10px; border: 1px solid #e0e0e0;">Tên hàng</th>
                                    <th style="width:100px; padding: 5px 10px; border: 1px solid #e0e0e0;">Tên phí</th>
                                    <th style="width:80px; padding: 5px 10px; border: 1px solid #e0e0e0;">Loại phí</th>
                                    <th style="width:80px; padding: 5px 10px; border: 1px solid #e0e0e0;">Số tiền</th>
                                    <th style="width:80px; padding: 5px 10px; border: 1px solid #e0e0e0;">VAT</th>
                                    <th style="width:80px; padding: 5px 10px; border: 1px solid #e0e0e0;">Thành tiền</th>
                                </tr>
                            </thead>
                            <tbody v-for="(item, i) in details" :key="item.id">
                                <tr>
                                    <td style="font-size: 12px; text-align: center; border: 1px solid #e0e0e0; padding: 5px 10px;">{{i + 1}}</td>
                                    <td style="font-size: 12px; border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;">{{item.code}}</td>
                                    <td style="font-size: 12px; border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;">{{item.goodsName}}</td>
                                    <td style="font-size: 12px; border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;">{{item.feeName}}</td>
                                    <td style="font-size: 12px; border: 1px solid #e0e0e0; padding: 5px 10px 5px 20px;">
                                        <span v-if="item.type == 1">Phí dịch vụ</span>
                                        <span v-else>Chi hộ</span>
                                    </td>
                                    <td style="font-size: 12px; text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(item.amount)}}</td>
                                    <td style="font-size: 12px; text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(item.vat)}}%</td>
                                    <td style="font-size: 12px; text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(item.pay)}}</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td colspan="7" style="font-size: 12px;font-weight: bold; text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"><b>Chi phí dịch vụ</b></td>
                                    <td style="font-size: 12px;font-weight: bold; text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(entity.payFee)}}</td>
                                </tr>
                                <tr>
                                    <td colspan="7" style="font-size: 12px;font-weight: bold; text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"><b>Chi hộ</b></td>
                                    <td style="font-size: 12px;font-weight: bold; text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(entity.payOnBehalf)}}</td>
                                </tr>
                                <tr>
                                    <td colspan="7" style="font-size: 12px;font-weight: bold; text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;"><b>Tổng dự toán</b></td>
                                    <td style="font-size: 12px;font-weight: bold; text-align: right; border: 1px solid #e0e0e0; padding: 5px 10px;">{{formatNumber(entity.pay)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="display: flex; justify-content: space-around; padding-top: 80px;"> 
                        <div v-for="item in entity.approveList" :key="item.id" style="margin-bottom: 50px; padding-bottom: 50px;">
                            <div v-show="!item.isCommenter" style="display: flex; flex-direction: column; justify-content: center; ">
                                <div style="font-size: 15px; font-weight: bold;">{{item.title}}</div>
                                <div style="font-size: 15px; font-weight: bold; padding-top: 70px;">{{item.staff.fullName}}</div>
                                <div>{{item.note}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </md-content>
        </md-content>
    </md-dialog>
</template>

<script>
    import downloadButton from '../../../components/DownloadButton.vue';

    export default ({
        components: {
            downloadButton
        },
        data() {
            return {
               title: 'Dự toán chi phí điều động phương tiện',
               id: 0,
               showDialog: false,
               loadding: false,
               details: [],
               entity: { id: 0, files: [], approveList: [], details: [], staffId: '', staff: {}, estimateCode: '', estimateDate: null, status: 0, amount: 0, vat: 0, pay: 0 },
            }
        },
        methods: {
            async open(obj){
                this.entity = obj;
                this.showDialog = true;
                this.details = [];
                for(let i = 0; i < this.entity.details.length; i ++){
                    let obj = this.entity.details[i];
                    let item = { id: obj.id, feeType: 1, code: 'N/A', goodsName: 'Phí chung', feeCode: obj.fee.feeCode, feeName: obj.fee.feeName, type: obj.type, amount: obj.amount, vat: obj.vat, pay: obj.pay };
                    this.details.push(item);
                }
                for(let i = 0; i < this.entity.goodsDetails.length; i ++){
                    let obj = this.entity.goodsDetails[i];
                    let item = { id: obj.id, feeType: 2, code: obj.orderDetail.code, goodsName: obj.goods.goodsName, feeCode: obj.fee.feeCode, feeName: obj.fee.feeName, type: obj.type, amount: obj.amount, vat: obj.vat, pay: obj.pay };
                    this.details.push(item);
                }
            },

            print () {
                this.$htmlToPaper('previewContent');
            },
        },
    })
</script>


<style lang="css" scoped>
    .preview-title {
        font-size: 16px;
    }
    .preview-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 810px;
        min-height: 600px;
        height: auto;
        max-width: 810px;
    }
    .md-scrollbar {
        height: 600px;
        padding-bottom: 20px;
    }
    .preview-content {
        padding: 10px 15px;
    }
</style>